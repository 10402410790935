import { cn } from '#app/utils/misc.tsx';
import { type IconName } from '@/icon-name';
import { type SVGProps } from 'react';

import href from './icons/sprite.svg';

export { href };
export { IconName };

const sizeClassName = {
  large    : 'w-5 h-5',
  medium   : 'w-4 h-4',
  small    : 'w-3.5 h-3.5',
  ssmall   : 'w-3 h-3',
  xMedium  : 'w-[1.125rem] h-[1.125rem]',
  xlarge   : 'w-6 h-6',
  xslarge  : 'w-7 h-7',
  xsmall   : 'w-2.5 h-2.5',
  xxlarge  : 'w-8 h-8',
  xxxxlarge: 'w-[3.75rem] h-[3.75rem]',
} as const;

type Size = keyof typeof sizeClassName;

const childrenSizeClassName = {
  large    : 'gap-2',
  medium   : 'gap-2',
  small    : 'gap-1.5',
  ssmall   : 'gap-1.5',
  xMedium  : 'gap-2',
  xlarge   : 'gap-2',
  xslarge  : 'gap-2',
  xsmall   : 'gap-1.5',
  xxlarge  : 'gap-2',
  xxxxlarge: 'gap-2',
} satisfies Record<Size, string>;

/**
 * Renders an SVG icon. The icon defaults to the size of the font. To make it
 * align vertically with neighboring text, you can pass the text as a child of
 * the icon and it will be automatically aligned.
 * Alternatively, if you're not ok with the icon being to the left of the text,
 * you need to wrap the icon and text in a common parent and set the parent to
 * display "flex" (or "inline-flex") with "items-center" and a reasonable gap.
 */
export function Icon({
  children,
  className,
  name,
  size = 'small',
  ...props
}: SVGProps<SVGSVGElement> & {
	name: IconName
	size?: Size
}) {

  if (size === 'large') {

  }

  if (children) {
    return (
      <span
        className={`inline-flex items-center ${childrenSizeClassName[size]}`}
      >
        <Icon className={className} name={name} size={size} {...props} />
        {children}
      </span>
    );
  }
  return (
    <svg
      {...props}
      className={cn(sizeClassName[size], 'inline self-center', className)}
    >
      <use href={`${href}#${name}`} />
    </svg>
  );
}
